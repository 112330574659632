import gql from'graphql-tag';import{userMyFoxFragment,calendarFragment,noteFragment,billFragment}from'./Fragments';export const listCustomersQuery=gql`
	query ListCustomersQuery($customerIds: [String!], $subjectsIds: [String!]) {
		listCustomers(where: {id: {in: $customerIds}, subjectId: {in: $subjectsIds}}, orderBy: {surname: ASC}) {
			id
			createdAt
			star
			warning
			name
			surname
			email
			phone
			address
			ean
			picture {
				id
				name
				url
				secret
			}
			userMyFox {
				id
				customers {
					id
					subject {
						id
					}
				}
			}
			subject {
				id
				name
				ico
			}
			calendars {
				from
			}
		}
	}
`;export const listCustomersSimpleQuery=gql`
	query ListCustomersSimpleQuery($subjectsIds: [String!], $skip: Int, $take: Int) {
		listCustomers(where: {subjectId: {in: $subjectsIds}}, orderBy: [{createdAt: DESC}], skip: $skip, take: $take) {
			id
			star
			warning
			name
			surname
			email
			phone
			address
			locale
			createdAt
			ean
			credit
			creditExpAt
			bills {
				id
			}
			subjectId
			subject {
				id
			}
			picture {
				id
				name
				url
				secret
			}
		}
	}
`;export const getCustomersSimpleByIdQuery=gql`
	query GetCustomerSimpleByIdQuery($customerId: String!) {
		getCustomer(where: {id: $customerId}) {
			id
			star
			warning
			name
			surname
			email
			phone
			address
			locale
			createdAt
			ean
			credit
			creditExpAt
			picture {
				id
				name
				url
				secret
			}
			userMyFox {
				...userMyFox
				customers {
					id
					subject {
						id
					}
					name
					surname
					email
				}
			}
			subject {
				id
				name
				ico
			}
		}
	}

	${userMyFoxFragment}
`;export const listCustomersSimpleQueryMany=gql`
	query ListCustomersSimpleQueryMany($subjectsIds: [String!], $take: Int, $skip: Int) {
		listCustomers(where: {subjectId: {in: $subjectsIds}}, orderBy: [{createdAt: DESC}], take: $take, skip: $skip) {
			id
			star
			warning
			name
			surname
			email
			phone
			address
			locale
			createdAt
			ean
			credit
			creditExpAt
			picture {
				id
				secret
			}
			bills {
				id
			}
			subjectId
			subject {
				id
			}
			userMyFox {
				id
				customers {
					id
					subjectId
					subject {
						id
					}
				}
			}
		}
	}
`;export const customerNextVisitBatchQuery=gql`
	query CustomerNextVisitBatchQuery($now: AWSDateTime!, $customerIds: [String!]!) {
		listCalendars(
			where: {
				from: {gte: $now}
				state: {in: [Open, Paid, Test]}
				calendarToCustomers: {some: {customerId: {in: $customerIds}}}
			}
		) {
			from
			calendarToCustomers {
				customerId
			}
		}
	}
`;export const getCustomerQuery=gql`
	query GetCustomerQuery($customerId: String!) {
		getCustomer(where: {id: $customerId}) {
			id
			createdAt
			star
			warning
			name
			surname
			email
			phone
			address
			locale
			ean
			credit
			creditExpAt
			picture {
				id
				name
				url
				secret
			}
			userMyFox {
				...userMyFox
				customers {
					id
					subject {
						id
					}
					name
					surname
					email
					calendars {
						...calendar
						bills {
							id
							note
							paidAt
							meta
							state
							stornoAt
							totalPrice
							carts {
								id
								priceVat
								count
							}
							stornoBill {
								id
							}
						}
					}
					bills {
						...bill
					}
					calendarToCustomers {
						calendar {
							...calendar
							bills {
								id
								note
								meta
							}
						}
					}
				}
			}
			subject {
				id
				name
				ico
			}
			notes {
				...note
			}
			bills {
				...bill
			}
		}
	}
	${userMyFoxFragment}
	${calendarFragment}
	${noteFragment}
	${billFragment}
`;export const createCustomerMutation=gql`
	mutation CreateCustomerMutation(
		$userMyFoxInputData: UserMyFoxCreateWithoutCustomerInput
		$userMyFoxConnect: UserMyFoxWhereUniqueInput
		$subject: SubjectWhereUniqueInput!
		$name: String
		$email: String
		$phone: String
		$surname: String
		$address: String
		$locale: Locale
		$ean: String
	) {
		createCustomer(
			data: {
				userMyFox: {create: $userMyFoxInputData, connect: $userMyFoxConnect}
				subject: {connect: $subject}
				name: $name
				email: $email
				phone: $phone
				surname: $surname
				address: $address
				locale: $locale
				ean: $ean
			}
		) {
			id
			createdAt
			star
			warning
			name
			surname
			email
			phone
			address
			locale
			credit
			creditExpAt
			picture {
				id
				name
				url
				secret
			}
			userMyFox {
				...userMyFox
				customers {
					id
					subjectId
					subject {
						id
					}
				}
			}
			bills {
				id
			}
			subject {
				id
				name
				ico
			}
			notes {
				...note
			}
			calendars {
				...calendar
			}
		}
	}
	${userMyFoxFragment}
	${calendarFragment}
	${noteFragment}
`;export const mergeCustomersMutation=gql`
	mutation MergeCustomersMutation($masterCustomerId: String!, $othersCustomersIds: [String!]!) {
		mergeCustomers(where: {masterCustomerId: $masterCustomerId, othersCustomersIds: $othersCustomersIds}) {
			success
			message
			updatedCustomer {
				id
				createdAt
				star
				warning
				name
				surname
				email
				phone
				address
				locale
				ean
				credit
				creditExpAt
				picture {
					id
					name
					url
					secret
				}
				userMyFox {
					...userMyFox
					customers {
						id
						subjectId
						subject {
							id
						}
						name
						surname
						email
					}
				}
				subject {
					id
					name
					ico
				}
				notes {
					...note
				}
				bills {
					...bill
				}
			}
		}
	}

	${userMyFoxFragment}
	${calendarFragment}
	${noteFragment}
	${billFragment}
`;export const updateCustomerMutation=gql`
	mutation UpdateCustomerMutation(
		$customerId: String!
		$name: String
		$email: String
		$phone: String
		$surname: String
		$address: String
		$warning: Boolean
		$star: Boolean
		$userPictureId: FileWhereUniqueInput
		$locale: Locale
		$ean: String
		$credit: Float
		$creditExpAt: AWSDateTime
	) {
		updateCustomer(
			where: {id: $customerId}
			data: {
				name: $name
				email: $email
				phone: $phone
				surname: $surname
				address: $address
				locale: $locale
				warning: $warning
				star: $star
				ean: $ean
				picture: {connect: $userPictureId}
				credit: $credit
				creditExpAt: $creditExpAt
			}
		) {
			id
			createdAt
			star
			warning
			name
			surname
			email
			phone
			address
			locale
			credit
			creditExpAt
			picture {
				id
				name
				url
				secret
			}
			userMyFox {
				...userMyFox
				customers {
					id
					subject {
						id
					}
				}
			}
			subject {
				id
				name
				ico
			}
			bills {
				id
			}
			notes {
				...note
			}
			calendars {
				...calendar
			}
		}
	}
	${userMyFoxFragment}
	${calendarFragment}
	${noteFragment}
`;export const eraseCustomerMutation=gql`
	mutation EraseCustomerMutation($customerId: String!) {
		eraseCustomer(where: {customerId: $customerId}) {
			success
			message
		}
	}
`;export const canDeleteCustomer=gql`
	query CanDeleteCustomer($customerId: String!) {
		getCustomer(where: {id: $customerId}) {
			id
			name
			surname
			subject {
				id
			}
			bills {
				id
			}
			calendarToCustomers {
				id
			}
			calendars {
				id
			}
		}
	}
`;export const customerOrEmployeeIncomeQuery=gql`
	query CustomerOrEmployeeIncomeQuery(
		$customersIds: [String!]
		$employeeId: String
		$from: AWSDateTime
		$to: AWSDateTime
	) {
		customerOrEmployeeIncome(where: {customersIds: $customersIds, employeeId: $employeeId, from: $from, to: $to}) {
			income
			visits
			incomeByType
		}
	}
`;export const deleteManyCustomersMutation=gql`
	mutation DeleteManyCustomersMutation($customersIds: [String!]!) {
		deleteManyCustomers(where: {customersIds: $customersIds}) {
			success
			message
		}
	}
`;export const uploadCustomersMutation=gql`
	mutation UploadCustomersMutation(
		$subjectId: String!
		$secret: String!
		$sheet: String
		$name: String
		$surname: String
		$email: String
		$phone: String
		$note: String
		$street: String
		$city: String
		$postcode: String
		$country: String
	) {
		uploadCustomers(
			where: {
				subjectId: $subjectId
				sheet: $sheet
				secret: $secret
				columnsNames: {
					name: $name
					surname: $surname
					email: $email
					phone: $phone
					note: $note
					street: $street
					city: $city
					postcode: $postcode
					country: $country
				}
			}
		) {
			message
			response
			totalCount
			count
		}
	}
`;