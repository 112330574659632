import gql from'graphql-tag';import{calendarFragment,billFragment}from'./Fragments';export const getBillQuery=gql`
	query GetBillQuery($id: String) {
		getBill(where: {id: $id}) {
			...bill
		}
	}
	${billFragment}
`;export const listBillsQuery=gql`
	query ListBillsQuery(
		$id: String
		$state: BillState
		$shopId: String
		$subjectsIds: [String!]
		$from: AWSDateTime
		$to: AWSDateTime
	) {
		listBills(
			where: {
				id: {equals: $id}
				state: {equals: $state}
				subjectId: {in: $subjectsIds}
				shopId: {equals: $shopId}
				paidAt: {gte: $from, lte: $to}
			}
			orderBy: [{paidAt: DESC}]
		) {
			...bill
		}
	}
	${billFragment}
`;export const parkedBillsQuery=gql`
	query ListBillsQuery(
		$state: BillState
		$shopId: String
		$subjectsIds: [String]
		$from: AWSDateTime
		$to: AWSDateTime
	) {
		listBills(
			where: {
				state: {equals: $state}
				subjectId: {in: $subjectsIds}
				shopId: {equals: $shopId}
				updatedAt: {gte: $from, lte: $to}
				orderBy: {paidAt: DESC}
			}
		) {
			...bill
		}
	}
	${billFragment}
`;export const payedBillsQuery=gql`
	query ListBillsQuery(
		$states: [BillState!]
		$shopId: String
		$subjectsIds: [String!]
		$from: AWSDateTime
		$to: AWSDateTime
		$skip: Int
		$take: Int
	) {
		listBills(
			where: {
				state: {in: $states}
				subjectId: {in: $subjectsIds}
				shopId: {equals: $shopId}
				paidAt: {gte: $from, lte: $to}
			}
			orderBy: {paidAt: DESC}
			skip: $skip
			take: $take
		) {
			id
			meta
			note
			state
			paidAt
			updatedAt
			billNumber
			totalPrice
			customers {
				id
				name
				surname
			}
			subject {
				id
			}
			employee {
				id
			}
			stornoBill {
				id
				paidAt
			}
			stornedBill {
				id
				paidAt
			}
		}
	}
`;export const sumUpRefundTransactionMutation=gql`
	mutation SumUpRefundTransactionMutation($transactionId: String!, $subjectId: String!, $amount: Float) {
		sumUpRefundTransaction(where: {transactionId: $transactionId, subjectId: $subjectId}, data: {amount: $amount}) {
			success
			responseCode
			message
		}
	}
`;export const createDefaultBillMutation=gql`
	mutation CreateBillMutation($shopId: String, $subjectId: String, $calendarIdConnection: CalendarWhereUniqueInput) {
		createBill(
			data: {
				state: Regular
				totalPrice: 0.0
				calendar: {connect: $calendarIdConnection}
				subject: {connect: {id: $subjectId}}
				shop: {connect: {id: $shopId}}
			}
		) {
			...bill
		}
	}
	${billFragment}
`;export const deleteBillMutation=gql`
	mutation DeleteBillMutation($id: String!) {
		deleteBill(where: {id: $id}) {
			id
		}
	}
`;export const deleteBillPaymentsMutation=gql`
	mutation DeleteBillPaymentMutation($id: String!) {
		deleteBillPayment(where: {id: $id}) {
			id
		}
	}
`;export const getMetaFromBillQuery=gql`
	query GetQueryFromBillMutation($id: String!) {
		getBill(where: {id: $id}) {
			id
			meta
		}
	}
`;export const updateBillMutation=gql`
	mutation UpdateBillMutation(
		$id: String!
		$billNumber: String
		$name: String
		$cartsIds: [CartWhereUniqueInput!]
		$customersIds: [CustomerWhereUniqueInput!]
		$employeeId: EmployeeWhereUniqueInput
		$disconnectEmployee: Boolean
		$note: String
		$paidAt: AWSDateTime
		$calendarId: CalendarWhereUniqueInput
		$shopId: ShopWhereUniqueInput
		$subjectId: SubjectWhereUniqueInput
		$meta: AWSJSON
		$stornoAt: AWSDateTime
		$stornoBillId: BillWhereUniqueInput
		$stornedBillId: BillWhereUniqueInput
		$billPayments: [BillPaymentCreateWithoutBillInput!]
		$billPaymentsIds: [BillPaymentWhereUniqueInput!]
		$discountId: DiscountWhereUniqueInput
		$disconnectDiscount: Boolean
		$state: BillState
		$totalPrice: Float
	) {
		updateBill(
			where: {id: $id}
			data: {
				billNumber: $billNumber
				name: $name
				carts: {set: $cartsIds}
				customers: {set: $customersIds}
				employee: {connect: $employeeId, disconnect: $disconnectEmployee}
				note: $note
				paidAt: $paidAt
				calendar: {connect: $calendarId}
				shop: {connect: $shopId}
				subject: {connect: $subjectId}
				meta: $meta
				stornoAt: $stornoAt
				stornoBill: {connect: $stornoBillId}
				stornedBill: {connect: $stornedBillId}
				billPayments: {create: $billPayments, connect: $billPaymentsIds}
				discount: {connect: $discountId, disconnect: $disconnectDiscount}
				state: $state
				totalPrice: $totalPrice
			}
		) {
			...bill
		}
	}
	${billFragment}
`;export const calendarForBillQuery=gql`
	query CalendarForBillQuery($calendarId: String) {
		getCalendar(where: {id: $calendarId}) {
			...calendar
			bills {
				...bill
			}
		}
	}
	${calendarFragment}
	${billFragment}
`;export const createDiscountMutation=gql`
	mutation CreateDiscountMutation($discount_total: Float, $discount_percent: Float, $meta: AWSJSON) {
		createDiscount(data: {discount_total: $discount_total, discount_percent: $discount_percent, meta: $meta}) {
			id
		}
	}
`;export const updateDiscountMutation=gql`
	mutation UpdateDiscountMutation($id: String!, $discount_total: Float, $discount_percent: Float, $meta: AWSJSON) {
		updateDiscount(
			where: {id: $id}
			data: {discount_total: $discount_total, discount_percent: $discount_percent, meta: $meta}
		) {
			id
		}
	}
`;export const paymentTypesForBillQuery=gql`
	query PaymentTypes($subjectId: String) {
		listPaymentTypes(where: {subjectId: {equals: $subjectId}}) {
			id
			payType
		}
	}
`;export const createBillPaymentMutation=gql`
	mutation CreateBillPaymentMutation(
		$amount: Float!
		$paymentTypeId: PaymentTypeWhereUniqueInput
		$meta: AWSJSON
		$billId: BillWhereUniqueInput
	) {
		createBillPayment(
			data: {amount: $amount, meta: $meta, bill: {connect: $billId}, paymentType: {connect: $paymentTypeId}}
		) {
			id
		}
	}
`;export const updateBillPaymentMutation=gql`
	mutation UpdateBillPaymentMutation($id: String!, $amount: Float, $meta: AWSJSON) {
		updateBillPayment(where: {id: $id}, data: {amount: $amount, meta: $meta}) {
			id
			bill {
				...bill
			}
		}
	}
	${billFragment}
`;export const removeBillPaymentMutation=gql`
	mutation RemoveBillPaymentMutation($id: String!) {
		deleteBillPayment(where: {id: $id}) {
			id
		}
	}
`;export const billsForStatisticsQuery=gql`
	query BillsForStatisticsQuery(
		$shopId: String
		$subjectsIds: [String!]
		$billStates: [BillState!]
		$from: AWSDateTime
		$to: AWSDateTime
		$skip: Int
		$take: Int
	) {
		listBills(
			where: {
				shopId: {equals: $shopId}
				subjectId: {in: $subjectsIds}
				state: {in: $billStates}
				paidAt: {gte: $from, lte: $to}
			}
			orderBy: [{paidAt: DESC}]
			skip: $skip
			take: $take
		) {
			id
			state
			billNumber
			paidAt
			meta
			stornoBill {
				id
			}
			carts {
				id
				name
				count
				vat
				price
				priceVat
				item {
					id
					name
					type
					deleted
					categories {
						id
					}
				}
			}
			customers {
				id
				name
				surname
				email
				phone
				locale
			}
			employee {
				id
				userMyFox {
					id
					name
					surname
				}
			}
		}
	}
`;export const stornoBillsForStatisticsQuery=gql`
	query StornoBillsForStatisticsQuery(
		$shopId: String
		$subjectsIds: [String!]
		$from: AWSDateTime
		$to: AWSDateTime
		$skip: Int
		$take: Int
	) {
		listBills(
			where: {
				shopId: {equals: $shopId}
				subjectId: {in: $subjectsIds}
				paidAt: {gte: $from, lte: $to}
				OR: {stornoAt: {gte: $from}, state: {equals: Storno}}
			}
			orderBy: [{paidAt: DESC}]
			skip: $skip
			take: $take
		) {
			id
			state
			billNumber
			paidAt
			meta
			carts {
				id
				name
				count
				vat
				price
				priceVat
				item {
					id
					name
					type
					deleted
					categories {
						id
					}
				}
			}
			customers {
				id
				name
				surname
				email
				phone
				locale
			}
			employee {
				id
				userMyFox {
					id
					name
					surname
				}
			}
		}
	}
`;export const lastBillQuery=gql`
	query LastBillQuery($shopId: String!, $subjectId: String!) {
		listBills(
			where: {
				shopId: {equals: $shopId}
				subjectId: {equals: $subjectId}
				billNumber: {isNull: false}
				state: {in: [Payed, Storno]}
			}
			orderBy: {paidAt: DESC}
			take: 1
		) {
			id
			billNumber
		}
	}
`;