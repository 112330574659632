import gql from'graphql-tag';import{openingHeadFragment,addressFragment,shopFragment}from'./Fragments';export const getShopQuery=gql`
	query GetShopQuery($id: String!) {
		getShop(where: {id: $id}) {
			id
			name
			timezone
			snippetMinutes
			snippetBookFrom
			snippetBookTo
			displayPrices
			openOnHoliday
			email
			phone
			address {
				...address
			}
			openingHeads {
				...openingHead
			}
			subjectToShops {
				subject {
					id
				}
				id
			}
			meta
		}
	}
	${addressFragment}
	${openingHeadFragment}
`;export const listShopsQuery=gql`
	query ListShopsQuery($subjectId: String!) {
		listShops(where: {subjectToShops: {some: {subjectId: {equals: $subjectId}}}}) {
			id
			name
			timezone
			snippetMinutes
			snippetBookFrom
			snippetBookTo
			canBeCancelBeforeStartCalendar
			displayPrices
			displayCustomer
			openOnHoliday
			email
			phone
			address {
				...address
			}
			meta
			subjectToShops {
				id
				subject {
					id
					ico
					sendEmail
					sendSms
				}
			}
		}
	}
	${addressFragment}
`;export const listShopStructureQuery=gql`
	query ListShopStructureQuery($subjectId: String!) {
		listShops(where: {subjectToShops: {some: {subjectId: {equals: $subjectId}}}}) {
			id
			name
			employees {
				id
				userMyFox {
					id
					name
					surname
				}
			}
		}
	}
`;export const createShopMutation=gql`
	mutation CreateShopMutation(
		$name: String!
		$timezone: String!
		$email: String
		$phone: String
		$snippetMinutes: Int!
		$snippetBookFrom: Int!
		$snippetBookTo: Int!
		$canBeCancelBeforeStartCalendar: Int
		$displayPrices: Boolean!
		$openOnHoliday: Boolean!
		$displayCustomer: Boolean
		$subjectToShopCreate: SubjectToShopCreateWithoutShopInput!
		$addressCreate: AddressCreateWithoutShopInput
		$openingHeadCreateInput: OpeningHeadCreateWithoutShopInput!
	) {
		createShop(
			data: {
				name: $name
				timezone: $timezone
				email: $email
				phone: $phone
				snippetMinutes: $snippetMinutes
				snippetBookFrom: $snippetBookFrom
				snippetBookTo: $snippetBookTo
				canBeCancelBeforeStartCalendar: $canBeCancelBeforeStartCalendar
				displayPrices: $displayPrices
				displayCustomer: $displayCustomer
				openOnHoliday: $openOnHoliday
				subjectToShops: {create: [$subjectToShopCreate]}
				address: {create: $addressCreate}
				openingHeads: {create: [$openingHeadCreateInput]}
			}
		) {
			id
			name
			timezone
			snippetMinutes
			snippetBookFrom
			snippetBookTo
			canBeCancelBeforeStartCalendar
			displayPrices
			openOnHoliday
			displayCustomer
			email
			phone
			meta
			address {
				...address
			}
			subjectToShops {
				id
				subject {
					id
					ico
					sendEmail
					sendSms
				}
				shop {
					...shop
				}

				bail {
					id
					isActive
					defaultAmount
					timeToPayAfterNewReservation
					timeToForfeitureMoneyBeforeReservation
					onlyForCustomers
					exceptStarMarkedCustomers
					onlyNewCustomers
					alwaysWarningMarkedCustomers
				}
			}
		}
	}
	${addressFragment}
	${shopFragment}
`;export const updateShopMutation=gql`
	mutation UpdateShopMutation(
		$id: String!
		$name: String!
		$timezone: String
		$addressId: AddressWhereUniqueInput
		$email: String
		$phone: String
		$snippetMinutes: Int
		$snippetBookFrom: Int
		$snippetBookTo: Int
		$canBeCancelBeforeStartCalendar: Int
		$openOnHoliday: Boolean
		$displayPrices: Boolean
		$displayCustomer: Boolean
		$addressCreate: AddressCreateWithoutShopInput
	) {
		updateShop(
			where: {id: $id}
			data: {
				name: $name
				timezone: $timezone
				address: {connect: $addressId}
				email: $email
				phone: $phone
				snippetMinutes: $snippetMinutes
				snippetBookFrom: $snippetBookFrom
				snippetBookTo: $snippetBookTo
				canBeCancelBeforeStartCalendar: $canBeCancelBeforeStartCalendar
				displayPrices: $displayPrices
				displayCustomer: $displayCustomer
				openOnHoliday: $openOnHoliday
				address: {create: $addressCreate}
			}
		) {
			id
			name
			timezone
			snippetMinutes
			snippetBookFrom
			snippetBookTo
			canBeCancelBeforeStartCalendar
			displayPrices
			displayCustomer
			openOnHoliday
			email
			phone
			meta
			address {
				...address
			}
			subjectToShops {
				id
				subject {
					id
					ico
					sendEmail
					sendSms
				}
			}
		}
	}
	${addressFragment}
`;export const updateSubjectToShopParkingMutation=gql`
	mutation UpdateSubjectToShopEetMutation(
		$id: String!
		$parkingLabels: [String!]!
		$updatedBy: UserMyFoxWhereUniqueInput
	) {
		updateSubjectToShop(
			where: {id: $id}
			data: {parkingLabels: {set: $parkingLabels}, updatedBy: {connect: $updatedBy}}
		) {
			id
			parkingLabels
		}
	}
`;export const completelyDeleteShopMutation=gql`
	mutation CompletelyDeleteShopMutation($shopId: String!) {
		completelyDeleteShop(where: {shopId: $shopId}) {
			success
			message
		}
	}
`;