import gql from'graphql-tag';import{addressFragment,shopFragment,featureFlagFragment,subjectFragment}from'./Fragments';export const getSubjectQuery=gql`
	query GetSubjectQuery($subjectId: String!) {
		getSubject(where: {id: $subjectId}) {
			id
			name
			marketingName
			alias
			agreement
			ico
			dic
			test
			locale
			currency
			country
			vatPayer
			supportAccess
			isSumUpCardPaymentActive
			showTutorialBox
			distributor {
				id
				name
			}
			address {
				...address
			}
			subjectToShops {
				id
				shop {
					...shop
				}
				subject {
					id
				}
				bail {
					id
					isActive
					defaultAmount
					timeToPayAfterNewReservation
					timeToForfeitureMoneyBeforeReservation
					onlyForCustomers
					exceptStarMarkedCustomers
					onlyNewCustomers
					alwaysWarningMarkedCustomers
				}
			}
			subjectExtIds {
				id
				extId
				code
				meta
			}
			sendSms
			sendEmail
			featureFlags {
				...featureFlag
			}
			isSumUpCardPaymentActive
		}
	}
	${addressFragment}
	${shopFragment}
	${featureFlagFragment}
`;export const isAliasAvailableQuery=gql`
	query IsAliasAvailableQuery($alias: String!, $subjectId: String) {
		isAliasAvailable(where: {alias: $alias, subjectId: $subjectId}) {
			success
			message
		}
	}
`;export const updateSubjectMutation=gql`
	mutation UpdateSubjectMutation(
		$subjectId: String!
		$name: String
		$marketingName: String
		$alias: String
		$ico: String
		$dic: String
		$addressId: AddressWhereUniqueInput
		$sendSms: Boolean
		$sendEmail: Boolean
		$vatPayer: Boolean
		$supportAccess: Boolean
		$agreement: AWSDateTime
		$isSumUpCardPaymentActive: Boolean
		$showTutorialBox: Boolean
	) {
		updateSubject(
			where: {id: $subjectId}
			data: {
				name: $name
				marketingName: $marketingName
				alias: $alias
				agreement: $agreement
				ico: $ico
				vatPayer: $vatPayer
				dic: $dic
				supportAccess: $supportAccess
				address: {connect: $addressId}
				sendSms: $sendSms
				sendEmail: $sendEmail
				isSumUpCardPaymentActive: $isSumUpCardPaymentActive
				showTutorialBox: $showTutorialBox
			}
		) {
			id
			name
			marketingName
			alias
			agreement
			ico
			dic
			vatPayer
			supportAccess
			address {
				...address
			}
			sendSms
			sendEmail
			isSumUpCardPaymentActive
			showTutorialBox
		}
	}
	${addressFragment}
`;export const setFeatureFlagsForSubjectMutation=gql`
	mutation UpdateFeatureFlagsForSubjectMutation($subjectId: String!, $setFeat: [FeatureFlagWhereUniqueInput!]) {
		updateSubject(where: {id: $subjectId}, data: {featureFlags: {set: $setFeat}}) {
			id
			featureFlags {
				...featureFlag
			}
		}
	}
	${featureFlagFragment}
`;export const updateSubjectAdminMutation=gql`
	mutation UpdateSubjectAdminMutation(
		$subjectId: String!
		$type: SubjectType
		$locale: Locale
		$currency: Currency
		$phoneCountryPrefix: Country
		$alias: String
		$test: Boolean
		$vatPayer: Boolean
		$ico: String
		$regNote: String
	) {
		updateSubject(
			where: {id: $subjectId}
			data: {
				type: $type
				ico: $ico
				regNote: $regNote
				alias: $alias
				locale: $locale
				currency: $currency
				phoneCountryPrefix: $phoneCountryPrefix
				test: $test
				vatPayer: $vatPayer
			}
		) {
			id
			type
			locale
			currency
			phoneCountryPrefix
			test
			vatPayer
			regNote
		}
	}
`;export const updateSubjectCurrencyMutation=gql`
	mutation UpdateSubjectCurrencyMutation($subjectId: String!, $currency: Currency!) {
		updateCurrency(where: {subjectId: $subjectId, currency: $currency}) {
			success
			message
		}
	}
`;export const allInvoicingSubjectsQuery=gql`
	query AllInvoicingSubjectsQuery($filter: SubjectWhereInput, $skip: Int, $take: Int) {
		listSubjects(where: $filter, orderBy: {createdAt: DESC}, skip: $skip, take: $take) {
			id
			name
			ico
			dic
			vatPayer
			currency
			phoneCountryPrefix
			accessStatus
			microsite {
				id
				website
			}
			mfInstance {
				id
				subjects {
					id
					ico
					name
					createdAt
				}
			}
			createdAt
			updatedAt
			alias
			agreement
			test
			type
			locale
			regNote
			distributor {
				id
				name
			}
			featureFlags {
				id
				code
			}
			employees {
				id
				role
				bookEnabled
				userMyFox {
					id
					name
					surname
					email
					phone
					createdAt
					updatedAt
				}
			}
			subjectExtIds {
				id
				extId
				code
				meta
			}
		}
	}
`;export const listSubjectsWithSupportAccessQuery=gql`
	query ListSubjectsWithSupportAccessQuery {
		listSubjects(where: {supportAccess: {equals: true}}) {
			...subject
			locale
			mfInstance {
				id
				subjects {
					id
				}
			}
			employees {
				id
				role
				createdAt
				shops {
					id
					timezone
				}
				userMyFox {
					id
					name
					surname
					email
					phone
				}
			}
		}
	}
	${subjectFragment}
`;