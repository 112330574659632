import gql from'graphql-tag';import{customersBailFragment}from'./Fragments';export const updateCustomersBailMutation=gql`
	mutation UpdateCustomersBailMutation(
		$id: String!
		$state: BailState
		$refundedAt: AWSDateTime
		$archivedAt: AWSDateTime
	) {
		updateCustomersBail(where: {id: $id}, data: {state: $state, refundedAt: $refundedAt, archivedAt: $archivedAt}) {
			...customersBail
		}
	}
	${customersBailFragment}
`;export const deleteCustomersBailMutation=gql`
	mutation DeleteCustomersBailMutation($id: String!) {
		deleteCustomersBail(where: {id: $id}) {
			id
		}
	}
`;export const createBailMutation=gql`
	mutation CreateBailMutation(
		$subjectToShopId: String!
		$isActive: Boolean
		$defaultAmount: Int
		$timeToPayAfterNewReservation: Int
		$timeToForfeitureMoneyBeforeReservation: Int
		$onlyForCustomers: Boolean
		$exceptStarMarkedCustomers: Boolean
		$onlyNewCustomers: Boolean
		$alwaysWarningMarkedCustomers: Boolean
	) {
		createBail(
			data: {
				subjectToShop: {connect: {id: $subjectToShopId}}
				isActive: $isActive
				defaultAmount: $defaultAmount
				timeToPayAfterNewReservation: $timeToPayAfterNewReservation
				timeToForfeitureMoneyBeforeReservation: $timeToForfeitureMoneyBeforeReservation
				onlyForCustomers: $onlyForCustomers
				exceptStarMarkedCustomers: $exceptStarMarkedCustomers
				onlyNewCustomers: $onlyNewCustomers
				alwaysWarningMarkedCustomers: $alwaysWarningMarkedCustomers
			}
		) {
			id
		}
	}
`;export const updateBailMutation=gql`
	mutation UpdateBailMutation(
		$bailId: String!
		$isActive: Boolean
		$defaultAmount: Int
		$timeToPayAfterNewReservation: Int
		$timeToForfeitureMoneyBeforeReservation: Int
		$onlyForCustomers: Boolean
		$exceptStarMarkedCustomers: Boolean
		$onlyNewCustomers: Boolean
		$alwaysWarningMarkedCustomers: Boolean
	) {
		updateBail(
			where: {id: $bailId}
			data: {
				isActive: $isActive
				defaultAmount: $defaultAmount
				timeToPayAfterNewReservation: $timeToPayAfterNewReservation
				timeToForfeitureMoneyBeforeReservation: $timeToForfeitureMoneyBeforeReservation
				onlyForCustomers: $onlyForCustomers
				exceptStarMarkedCustomers: $exceptStarMarkedCustomers
				onlyNewCustomers: $onlyNewCustomers
				alwaysWarningMarkedCustomers: $alwaysWarningMarkedCustomers
			}
		) {
			id
		}
	}
`;export const sumUpCancelBailMutation=gql`
	mutation SumUpCancelBailMutation($calendarId: String!, $calendarToCustomerId: String) {
		sumUpCancelBail(where: {calendarId: $calendarId, calendarToCustomerId: $calendarToCustomerId}) {
			success
		}
	}
`;export const listBailsQuery=gql`
	query ListBailsQuery(
		$subjectId: String
		$shopId: String
		$from: AWSDateTime
		$to: AWSDateTime
		$customerId: String
		$sumupCheckoutId: String
		$transactionCode: String
	) {
		listCustomersBails(
			where: {
				sumupCheckoutId: {equals: $sumupCheckoutId}
				transactionCode: {equals: $transactionCode}
				updatedAt: {gte: $from, lte: $to}
				customerId: {equals: $customerId}
				subjectToShop: {subjectId: {equals: $subjectId}, shopId: {equals: $shopId}}
			}
		) {
			id
			amount
			createdAt
			updatedAt
			dueTo
			paidAt
			refundedAt
			expiredAt
			archivedAt
			refundUntil
			state
			sumupCheckoutId
			transactionCode
			transactionId
			refundFailedReason
			refundFailedAmount
			customer {
				id
				name
				surname
				email
				phone
			}

			subjectToShop {
				subject {
					id
					ico
					currency

					tokens {
						meta
					}
				}

				shop {
					id
					name
				}
			}
		}
	}
`;export const listCustomersOnlyWithBailQuery=gql`
	query ListCustomersOnlyWithBailQuery($subjectsIds: [String!], $take: Int, $skip: Int) {
		listCustomersBails(
			where: {customer: {subjectId: {in: $subjectsIds}}}
			orderBy: {createdAt: DESC}
			take: $take
			skip: $skip
		) {
			customer {
				id
				star
				warning
				name
				surname
				email
				phone
				address
				locale
				createdAt
				ean
				credit
				creditExpAt
				subject {
					id
				}
				picture {
					id
					name
					url
					secret
				}
			}
		}
	}
`;export const listCustomersBails=gql`
	query ListCustomersBails(
		$shopId: String
		$from: AWSDateTime
		$to: AWSDateTime
		$subjectsIds: [String!]
		$customersIds: [String!]
		$bailStates: [BailState!]
	) {
		listCustomersBails(
			where: {
				updatedAt: {lte: $to, gte: $from}
				state: {in: $bailStates}
				subjectToShop: {subjectId: {in: $subjectsIds}, shopId: {equals: $shopId}}
				OR: [{customerId: {in: $customersIds}}, {customer: {is: null}}]
			}
		) {
			id
			amount
			createdAt
			updatedAt
			dueTo
			paidAt
			refundedAt
			expiredAt
			automaticCanceledAt
			refundUntil
			state
			sumupCheckoutId
			transactionId
			transactionCode
			customer {
				id
				name
				surname
				email
				phone
				picture {
					id
					name
					url
					secret
				}
			}
			calendarToCustomer {
				id
				calendar {
					id
					from
					state
					carts {
						id
						name
						priceVat
					}
					bills {
						id
						billPayments {
							id
							paymentType {
								id
								payType
							}
							bill {
								id
								customers {
									id
								}
							}
						}
					}
				}
				customer {
					id
					name
					surname
					email
					phone
					picture {
						id
						name
						url
						secret
					}
				}
			}
			subjectToShop {
				id
				subject {
					id
					name
					ico
				}
			}
		}
	}
`;export const listCustomersBailsReportQuery=gql`
	query ListCustomersBailsQuery($subjectId: String!, $shopId: String, $bailStates: [BailState!]) {
		listCustomersBails(
			where: {
				subjectToShop: {subjectId: {equals: $subjectId}, shopId: {equals: $shopId}}
				state: {in: $bailStates}
			}
			orderBy: {paidAt: DESC}
		) {
			id
			amount
			createdAt
			updatedAt
			dueTo
			paidAt
			refundedAt
			expiredAt
			automaticCanceledAt
			refundUntil
			state
			sumupCheckoutId
			transactionId
			transactionCode
			subjectToShop {
				id
				subject {
					id
					marketingName
				}
				shop {
					id
					name
				}
			}
			customer {
				id
				name
				surname
				email
			}
			calendarToCustomer {
				id
				calendar {
					id
					from
				}
			}
		}
	}
`;