import gql from'graphql-tag';export const listSubjectToShopByShopQuery=gql`
	query ListSubjectsByShopQuery($shopId: String!) {
		listSubjectToShops(where: {shopId: {equals: $shopId}}) {
			id
			subject {
				id
				name
				ico
				address {
					id
					city
					street
					zip
				}
			}
		}
	}
`;export const listSubjectToShopsQuery=gql`
	query ListSubjectToShopsQuery($subjectId: String!, $shopId: String) {
		listSubjectToShops(where: {subjectId: {equals: $subjectId}, shopId: {equals: $shopId}}) {
			id
			bail {
				id
				isActive
				defaultAmount
				timeToPayAfterNewReservation
				timeToForfeitureMoneyBeforeReservation
				onlyNewCustomers
			}
		}
	}
`;export const updateSubjectToShopMutation=gql`
	mutation UpdateSubjectToShopMutation($subjectToShopId: String!, $bailId: String!) {
		updateSubjectToShop(where: {id: $subjectToShopId}, data: {bail: {connect: {id: $bailId}}}) {
			id
		}
	}
`;